import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { OutboundLink } from "gatsby-plugin-google-analytics";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "welcome",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#welcome",
        "aria-label": "welcome permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Welcome!`}</h1>
    <p>{`HTML Content is a custom visual for `}<OutboundLink href="https://www.powerbi.com" target="_blank" mdxType="OutboundLink">{`Microsoft Power BI`}</OutboundLink>{`.`}</p>
    <p>{`Report authors can use the visual to write their own columns and measures `}<OutboundLink href="https://docs.microsoft.com/en-us/dax/" target="_blank" mdxType="OutboundLink">{`using DAX`}</OutboundLink>{`, to create dynamic HTML content, or render existing content from their data model that contains HTML in their reports.`}</p>
    <p>{`This website maintained by the author of the visual to provide as much reference information as possible, both for using the visual and the functionality available under the hood.`}</p>
    <p>{`Please refer to the menu to begin learning more about the visual, and thanks very much for using it!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      